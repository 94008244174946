.equaldiv{
    float: left;
    padding: 20px;
    width: 49%;
}
.dtable{
    width: 100%;
    text-align: left;
    border-radius: 11px;
}
.dtr{
    background: #504fd6;
    color: #fff;
    border: 2px #fff dotted;
}
.dtd2{
    background: #f2bd48;
    color: #504fd6;
    text-align: right;
}
@media only screen and (max-width: 600px) {
    .equaldiv{
        width: 90%;
        text-align: center;
    }
}